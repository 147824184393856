import React from 'react';
import ReactDOM from 'react-dom';
import SimpleForm from './SimpleForm';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/:team_id" children={<SimpleForm />} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);