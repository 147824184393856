import React from 'react';
import {
  withRouter
} from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

class SimpleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      message: '',
      subject: '',
      team_id: ''
    }
    this.submitForm = this.submitForm.bind(this);
    this.firstNameChange = this.firstNameChange.bind(this);
    this.lastNameChange = this.lastNameChange.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.phoneChange = this.phoneChange.bind(this);
    this.messageChange = this.messageChange.bind(this);
    this.subjectChange = this.subjectChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      team_id: this.props.match.params.team_id
    })
  }

  submitForm() {
    this.setState({isSubmitted: true})
    fetch(`${process.env.REACT_APP_API_URL}/slingshots`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        slingshot: {
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          phone: this.state.phone,
          email: this.state.email,
          message: this.state.message,
          subject: this.state.subject,
          team_id: this.state.team_id
        }
      })
    })
  }

  successContent() {
    return <Container className='mt-5'>
      <Row>
        <Col>
          <Alert variant="success">
            <Alert.Heading>Thank you!</Alert.Heading>
            <p>
              Thank you, we have received your message.  One of our team members will react out to you shortly.
            </p>
          </Alert>
        </Col>
      </Row>
    </Container>
  }

  firstNameChange(e) {
    this.setState({first_name: e.target.value})
  }

  lastNameChange(e) {
    this.setState({last_name: e.target.value})
  }

  phoneChange(e) {
    this.setState({phone: e.target.value})
  }

  emailChange(e) {
    this.setState({email: e.target.value})
  }

  messageChange(e) {
    this.setState({message: e.target.value})
  }

  subjectChange(e) {
    this.setState({subject: e.target.value})
  }

  render() {
    if (this.state.isSubmitted) {
      return this.successContent()
    }

    return <Container className='mt-5'>
      <Row>
        <Col id="main">
          <h1>Contact</h1>
          <Form>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" onChange={this.firstNameChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" onChange={this.lastNameChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" onChange={this.emailChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control type="tel" onChange={this.phoneChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Service</Form.Label>
              <Form.Control type="text" onChange={this.subjectChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Mesage</Form.Label>
              <Form.Control as="textarea" rows={3} onChange={this.messageChange} />
            </Form.Group>
            <Button variant="primary" type="submit" onClick={() => {this.submitForm()}}>
                Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  }
}

export default withRouter(SimpleForm);